import FinanceLandingPage from "pages/FinanceLanding/FinanceLandingPage";
import { lazy, useEffect } from "react";
import ReactGA from 'react-ga4';
import { useLocation, useRoutes } from 'react-router-dom';

//layout
const Layout = lazy(() => import('layout/DefaultLayout'))

// default routes
const Home = lazy(() => import('pages/home/Home'))
const NotFound = lazy(() => import("pages/page_not_found/NotFound"))

const SecurityPolicy = lazy(() => import("pages/security/Security"))
const TermsAndConditions = lazy(() => import("pages/terms-and-conditions"))
const PrivacyPolicy = lazy(() => import("pages/privacy-policy/PrivacyPolicy"))

export function UserRoutes() {
  const location = useLocation()
  useEffect(() => {
    ReactGA.initialize('G-Z2RLG2S9FP')
    ReactGA.send({ hitType: "pageview", page: location.pathname });

   }, [location]);
  return useRoutes([
    {
      path: '/',
      element: <Layout />,
      children: [
        { element: <Home />, index: true },
        { element: <TermsAndConditions />, path: "terms" },
        { element: <PrivacyPolicy />, path: "privacy" },
        { element: <SecurityPolicy />, path: "security" },
        { element: <FinanceLandingPage />, path: "finance/landing" },
        { path: '*', element: <NotFound /> },
      ]
    },
  ])
}
