import { createSlice } from '@reduxjs/toolkit';

const UserSlice = createSlice({
    name: 'User',
    initialState: {
        user: null
    },
    reducers: {
        setUser: (state, action) => state = { ...state, user: action.payload },
        logout: (state) => {
            localStorage.removeItem('user')
            return state = { ...state, user: null }
        }
    }
})

export const { setUser, logout } = UserSlice.actions;
export default UserSlice.reducer
