import { configureStore } from '@reduxjs/toolkit'
import userSlice from './reducers/user.slice'
import loadingSlice from './reducers/loading.slice'
import snackSlice from './reducers/snack.slice'

const store = configureStore({
    reducer: {
        user: userSlice,
        loading: loadingSlice,
        snack: snackSlice
    }
})

export default store
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
