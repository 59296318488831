import { Box, Stack } from '@mui/material'
import React from 'react'
import DeltaCard from './DeltaCard'

interface BreakdownPaymentsProps {
    label: string
    title: number
    background: string
    color: string
    list?: BreakdownPaymentsProps[]
}

export default function BreakdownPayments(props: BreakdownPaymentsProps) {

    const formatCurrency = (amount: number, obj?: { maxFractionDigits?: number }) => {
        let formatting_options = {
            style: 'currency',
            currency: 'USD',
            maximumFractionDigits: typeof obj?.maxFractionDigits === "number" ? obj?.maxFractionDigits : 2
        }
        let dollarString = new Intl.NumberFormat("en-US", formatting_options);
        return dollarString.format(amount)
    }

    const layout = (obj: BreakdownPaymentsProps) => {
        return (
            <Box>
                <DeltaCard
                    label={obj.label}
                    title={formatCurrency(obj.title)}
                    removeMaxWidth
                    mainCardStyle={{
                        background: obj.background,
                        color: obj.color,
                        border: `1px solid ${obj.color}`,
                        height: 'auto',
                    }}
                />
                {obj.list && !!obj.list.length &&
                    <Stack direction={{ xs: "column", md: "row" }} spacing={1} mt={3} >
                        {obj.list.map((item, index, arr) => {
                            const isLast = index === arr.length - 1;
                            return (
                                <Box key={`${obj.label}_${item.label}`} flex={(item.title / obj.title)} mt={0} pr={isLast ? 0 : 0} >
                                    {layout(item)}
                                </Box>
                            );
                        })}
                    </Stack>
                }
            </Box>
        )
    }

    return (
        <Box>
            {layout(props)}
        </Box>
    )
}
