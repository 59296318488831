import { Box, Stack, Typography } from '@mui/material'
import React from 'react'

const HostLevelBreakdown = require('assets/financeLanding/webp/HostLevelBreakdown.webp');
const OrderLevelBreakdown = require('assets/financeLanding/webp/OrderLevelBreakdown.webp');
const ItemLevelBreakdown = require('assets/financeLanding/webp/ItemLevelBreakdown.webp');

const obj = {
    host: {
        src: HostLevelBreakdown,
        title: "Host level breakdown",
        description: "Host-level breakdown invoices that show which orders were placed with a specific host.",
    },
    order: {
        src: OrderLevelBreakdown,
        title: "Order level breakdown",
        description: "Calculates earnings as (total revenue minus chargebacks) multiplied by commission.",
    },
    item: {
        src: ItemLevelBreakdown,
        title: "Item level breakdown",
        description: "Total revenue is calculated by adding orders and order items by order date in the week of accounting.",
    },
}

interface LevelBreakdownCardsProps {
    type: "host" | "order" | "item"
}

export default function LevelBreakdownCards(props: LevelBreakdownCardsProps) {
    const { type } = props;
    return (
        <Stack direction="row" alignItems="center" gap={2} p={2} bgcolor="white" borderRadius={"10px"} >
            <img
                src={obj[type].src}
                alt={obj[type].title}
                style={{ width: "60px", height: "105px", objectFit: "contain" }}
            />
            <Box>
                <Typography variant="body1" mb={2} ><b>{obj[type].title}</b></Typography>
                <Typography variant="subtitle2" >{obj[type].description}</Typography>
            </Box>
        </Stack>
    )
}
