import { Box, Card, CardContent, SxProps, Theme, Tooltip, Typography } from "@mui/material";
import TrendingUpIcon from '@mui/icons-material/TrendingUp'
import TrendingDownIcon from '@mui/icons-material/TrendingDown'
import InfoIcon from '@mui/icons-material/Info';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { useNavigate } from "react-router-dom";

export interface DeltaDateRangeType {
    previous_start_date: string,
    previous_end_date: string
}

export interface DeltaCardProps {
    title: string,
    subTitle?: string,
    label: string,
    subLabel?: string,
    delta?: number,
    dateRange?: DeltaDateRangeType,
    deltaReversed?: boolean,
    titleColor?: string
    // messageComp?: JSX.Element,
    extraComp?: JSX.Element,

    showArrow?: boolean,
    removeMaxWidth?: boolean,
    bigFont?: boolean,
    mainCardStyle?: SxProps<Theme>,

    title2?: string,
    subTitle2?: string,
    label2?: string,
    subLabel2?: string,
    delta2?: number,
    dateRange2?: DeltaDateRangeType,
    deltaReversed2?: boolean,
    rangeText?: string,
    route?: string,
    // messageComp2?: JSX.Element,
    extraComp2?: JSX.Element,
}

function DeltaCard(props: DeltaCardProps) {
    const navigate = useNavigate()

    const handleClick = () => {
        if (props.route) {
            navigate(props.route)
        }
    }
    const cardLayout = (label: string, title: string, obj: { dateRange?: DeltaDateRangeType, delta?: string | number, deltaReveised?: boolean, subTitle?: string, subLabel?: string, rangeText?: string, extraComp?: JSX.Element }) => {
        let deltaDownColors = {
            background: "#FFE5E5",
            color: "red"
        }
        let deltaUpColors = {
            background: "#E1FFB1",
            color: "green"
        }
        let deltaZero = {
            background: "rgba(128,128,128,0.25)",
            color: "#000"
        }
        if (obj.deltaReveised) {
            let obj = { ...deltaDownColors };
            deltaDownColors = { ...deltaUpColors };
            deltaUpColors = { ...obj };
        }
        return (
            <Box height="100%" display="flex" flexDirection="column" alignItems="stretch" flex={1} >
                <Box padding="0.7rem 0.7rem" flex={1} onClick={handleClick} sx={{ '&:hover': { cursor: 'pointer' } }} display="flex" flexDirection="column">
                    <Box display="flex" alignItems="flex-start" >
                        <Typography sx={{ fontSize: '0.9rem', flex: 1, fontWeight: 700, lineHeight: '1.2em', display: '-webkit-box', WebkitBoxOrient: "vertical", boxOrient: "vertical", WebkitLineClamp: 2, lineClamp: 2, overflow: "hidden", textOverflow: "ellipsis", color: props.mainCardStyle ? "inherit" : "black" }} >{label}</Typography>
                        {props.showArrow && <ChevronRightIcon />}
                    </Box>
                    {obj.subLabel && <Typography variant="caption" color="rgba(0,0,0,0.5)" component="p" >{obj.subLabel}</Typography>}
                    <Box marginBottom="0.5rem" flexGrow={1}>
                        <Typography sx={{ fontWeight: 600, fontSize: props.bigFont ? '2rem' : '1.6rem', color: props.titleColor || 'inherit' }} title={title} >{title}</Typography>
                        <Box display="flex" alignItems="center" >
                            <Typography sx={{ fontWeight: 600, lineHeight: '1em', fontSize: '1.2rem', color: '#000' }} title={obj.subTitle} >{obj.subTitle}</Typography>
                            {obj.subTitle?.length &&
                                <Tooltip title="Available for payout, it can take upto 7 days for this to be transferred to your bank account"
                                    placement="right"
                                    componentsProps={{
                                        tooltip: {
                                            sx: {
                                                bgcolor: 'white',
                                                color: 'black',
                                                boxShadow: '1px 2px 3px rgba(0,0,0,0.5)',
                                            }
                                        }
                                    }}
                                >
                                    <InfoIcon fontSize="small" color="secondary" />
                                </Tooltip>
                            }
                        </Box>
                    </Box>
                    <Box>
                        {typeof obj.delta === "number" &&
                            <Box
                                display='inline-flex'
                                alignItems={'center'}
                                gap='0.3rem'
                                sx={{
                                    background: obj.delta < 0 ? deltaDownColors.background : obj.delta === 0 ? deltaZero.background : deltaUpColors.background,
                                    padding: "0.15rem 0.5rem",
                                    borderRadius: '5rem',
                                }}
                            >
                                <>
                                    {obj.delta < 0 && <TrendingDownIcon sx={{ color: deltaDownColors.color }} fontSize="small" />}
                                    {obj.delta > 0 && <TrendingUpIcon sx={{ color: deltaUpColors.color }} fontSize="small" />}
                                    <Typography variant='subtitle2' sx={{ color: obj.delta < 0 ? deltaDownColors.color : obj.delta === 0 ? deltaZero.color : deltaUpColors.color, fontSize: '0.6rem' }} >{obj.delta}%</Typography>
                                </>
                            </Box>
                        }
                    </Box>
                    {/* {obj.dateRang &&
                        <>
                            <Typography sx={{ fontSize: "0.8rem", color: "rgba(18,41,55,0.5)" }} >{obj.rangeText ? obj.rangeText : "than previous date selected"}</Typography>
                            <Typography sx={{ fontSize: "0.8rem", color: "#000" }} >({formatDate(obj.dateRange.previous_start_date)} - {formatDate(obj.dateRange.previous_end_date)} vs {formatDate(dateRange.start)} - {formatDate(dateRange.end)})</Typography>
                        </>
                    } */}
                </Box>
                {/* {obj.messageComp &&
                    <Box paddingX={1} paddingY={1} bgcolor="rgba(230, 230, 230, 1)" >
                        {obj.messageComp}
                    </Box>
                } */}
                {obj.extraComp}
            </Box>
        )
    }

    const secondCardCond = props.title2 && props.label2 && props.dateRange2;

    return (
        <Card
            sx={{
                flex: secondCardCond ? 2 : 1,
                border: '1px solid #E6E6E6',
                maxWidth: props.removeMaxWidth ? undefined : secondCardCond ? "500px" : "250px",
                height: '100%',
                ...props.mainCardStyle,
            }}
        >
            <CardContent sx={{ padding: "0px !important", height: '100%' }} >
                <Box display="flex" alignItems="stretch" height="100%" >
                    {cardLayout(props.label, props.title, { dateRange: props.dateRange, delta: props.delta, subTitle: props.subTitle, subLabel: props.subLabel, deltaReveised: props.deltaReversed, rangeText: props.rangeText, extraComp: props.extraComp })}

                    {
                        secondCardCond &&
                        <>
                            <Box sx={{
                                width: "1px",
                                background: "rgba(0,0,0,0.1)",
                            }} ></Box>
                            {cardLayout(props.label2 || "", props.title2 || "", { dateRange: props.dateRange2, delta: props.delta2, subTitle: props.subTitle2, subLabel: props.subLabel2, deltaReveised: props.deltaReversed2, rangeText: props.rangeText, extraComp: props.extraComp2 })}
                        </>
                    }
                </Box >
            </CardContent >
        </Card >
    )
}

export default DeltaCard;