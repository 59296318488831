import { createTheme, ThemeOptions } from '@mui/material/styles'

interface Colors extends ThemeOptions {
    colors: any
}

const theme = createTheme({
    typography: {
        // fontFamily: ['Circular Std', 'sans-serif'].join(","),
        fontFamily: ['Sora', "sans-serif"].join(","),
        // fontWeightLight: 300,
        // fontWeightRegular: 400,
        // fontWeightMedium: 500,
        // fontWeightSemiBold: 600,
        // fontWeightBold: 700,
        allVariants: {
            color: '#09234B'
        }
    },
    palette: {
        primary: {
            main: '#DF5F2E',
            contrastText: '#ffffff'
        },
        secondary: {
            main: '#FEFAF7',
            contrastText: '#356C80'
        },
        // primary: {
        //     main: '#FEFAF7',
        //     contrastText: '#356C80'
        // },
        // secondary: {
        //     main: '#DF5F2E',
        //     contrastText: '#ffffff'
        // },
    },
    shadows: [
        "none",
        "0px 2px 4px rgba(0, 0, 0, 0.05)",
        "0px 2px 4px rgba(0, 0, 0, 0.1)",
        "0px 4px 8px rgba(0, 0, 0, 0.15)",
        "0px 4px 8px rgba(0, 0, 0, 0.2)",
        '2px 4px 30px rgba(155, 155, 155, 0.25)',
        '2px 4px 30px rgba(155, 155, 155, 0.3)',
        '2px 4px 30px rgba(155, 155, 155, 0.35)',
        '2px 4px 30px rgba(155, 155, 155, 0.25)',
        '2px 4px 30px rgba(155, 155, 155, 0.25)',
        '2px 4px 30px rgba(155, 155, 155, 0.25)',
        '2px 4px 30px rgba(155, 155, 155, 0.25)',
        '2px 4px 30px rgba(155, 155, 155, 0.25)',
        '2px 4px 30px rgba(155, 155, 155, 0.25)',
        '2px 4px 30px rgba(155, 155, 155, 0.25)',
        '2px 4px 30px rgba(155, 155, 155, 0.25)',
        "0px 4px 8px rgba(0, 0, 0, 1)",
        "0px 4px 8px rgba(0, 0, 0, 1)",
        "0px 4px 8px rgba(0, 0, 0, 1)",
        "0px 4px 8px rgba(0, 0, 0, 1)",
        "0px 4px 8px rgba(0, 0, 0, 1)",
        "0px 4px 8px rgba(0, 0, 0, 1)",
        "0px 4px 8px rgba(0, 0, 0, 1)",
        "0px 4px 8px rgba(0, 0, 0, 1)",
        "2px 4px 30px rgba(0,0,0,0.5)"
    ],
    shape: {
        borderRadius: 5
    },
    components: {
        MuiTextField: {
            defaultProps: {
                fullWidth: true,
                variant: 'outlined',
                // margin: 'normal',
            },
            styleOverrides: {
                root: {
                    "& .MuiFilledInput-root": {
                        "&::before": { borderBottom: "none", '&:hover': { border: "none" } },
                        "&::after": { borderBottom: "none", '&:hover': { border: "none" } }
                    },
                    '& fieldset': {
                        borderRadius: 0
                      }
                }
            }
        },
        MuiButton: {
            defaultProps: {
                variant: "contained",
                color: "primary",
                size: "large"
            },
            styleOverrides: {
                root: {
                    textTransform: "none",
                },
                sizeLarge: {
                    paddingTop: 12
                }
            }
        },
        MuiListItemText: {
            defaultProps: {
                primaryTypographyProps: {
                    color: "inherit"
                }
            }
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    textTransform: "none"
                }
            }
        },
        MuiAutocomplete: {
            styleOverrides: {
                root: {
                    border: 'none'
                }
            }
        }
    },
    colors: {
        primary: '#FEFAF7',
        secondary: '#DF5F2E',
        background: '#F4F7FF',
        backgroundDark: '#122937',
        text: "#09234B",
        teal: '#356C80',
        orange: "#DF5F2E",
        blue: "#214558",
        darkBlue: "#122937",
        inactiveLink: "#606C80",
        inactiveLink2: '#4F809D'
    },
} as Colors);

export default theme