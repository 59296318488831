import { CircularProgress } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { Loader, Snackbar } from 'components';
import { StyledBackdrop } from 'components/loader/Loader';
import { useEffect, useState } from 'react';
import theme from 'themes/defaultTheme';
import './App.css';
import { UserRoutes } from './routes';

function App() {
  const [imagesLoaded, setImagesLoaded] = useState(false);



  useEffect(() => {
    const imageSources = [
      require('./assets/webp/mac.webp')
    ];
    const images: HTMLElement[] = [];
    let loadedImages = 0;

    const handleImageLoad = () => {
      loadedImages++;

      if (loadedImages === imageSources.length) {
        setImagesLoaded(true);
      }
    };

    imageSources.forEach(src => {
      const img = new Image();
      img.src = src;
      img.addEventListener('load', handleImageLoad);
      images.push(img);
    });

    return () => {
      images.forEach(img => img.removeEventListener('load', handleImageLoad));
    };
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Snackbar />
      <Loader />
      {imagesLoaded ? (
      <UserRoutes />
      ) : (
        <StyledBackdrop open>
          <CircularProgress color="inherit" />
        </StyledBackdrop>
      )}

    </ThemeProvider>
  );
}

export default App;
