import React from "react";
import Box from '@mui/material/Box'
import Grid from "@mui/material/Grid";
import Stack from '@mui/material/Stack'
import Button from '@mui/material/Button'
import Divider from '@mui/material/Divider'
import Typography from '@mui/material/Typography'
import FinanceButton from "./components/FinanceButton";
import ImageCollapse from "./components/ImageCollapse";
import LevelBreakdownCards from "./components/LevelBreakdownCards";
import BreakdownPayments from "./components/BreakdownPayments";

// Images
// const LoopLogo = require('assets/logos/logo_full_png_512.png');
// const FooterLogo = require('assets/logos/logo2.png');
const FinanceAFA = require("assets/financeLanding/webp/Finance_AFA.webp");
const BarChartOutlined = require("assets/financeLanding/webp/BarChartOutlined.webp");
const AuditImg = require("assets/financeLanding/webp/Audit.webp");
const DisputesImg = require("assets/financeLanding/webp/Disputes.webp");
const InvoicesImg = require("assets/financeLanding/webp/Invoices.webp");
const CustomizeImg = require("assets/financeLanding/webp/Customize.webp");
const CustomizeSectionImg = require("assets/financeLanding/webp/CustomizeSection.webp");
const DisputeSectionImg = require("assets/financeLanding/webp/DisputeSection.webp");
const InsightDisputeImg = require("assets/financeLanding/webp/InsightDispute.webp");
const DetailedInvoiceImg = require("assets/financeLanding/webp/DetailedInvoice.webp");
// const SecureCirclesImg = require("assets/financeLanding/webp/SecureCircles.webp");
const DoordashImg = require("assets/financeLanding/webp/doordash.webp");
const GrubhubImg = require("assets/financeLanding/webp/grubhub.webp");
const UberEatsImg = require("assets/financeLanding/webp/ubereats.webp");

export default function FinanceLandingPage() {
  return (
    <Box mb={10}>
      <Box sx={{ mx: 10, mt: 10 }}>
        <Box>
          <Typography
            sx={{ fontWeight: 400, fontSize: "64px", textAlign: "center" }}
          >
            Manage your order finances, dispute orders, and understand your
            payment breakdown.
          </Typography>
        </Box>

        <Box sx={{ mt: 5 }}>
          <Typography variant="h4" fontWeight={400} textAlign="center">
            We make managing your business simple and easy.
          </Typography>
        </Box>

        <Stack alignItems="center" justifyContent="center" mt={5}>
          <Button 
            variant="contained" 
            href="https://app.loopkitchen.xyz/schedule" LinkComponent={'a'} target="_blank"
            sx={{ 
              background: "rgba(241, 84, 18, 1)", 
              color: "white", 
              "&:hover": { background: "rgba(241, 84, 18, 1)", color: "white" }
            }}>
            Demo
          </Button>
        </Stack>

        <Stack alignItems="center" justifyContent="center" mt={5}>
          <Box sx={{ width: "80%", position: "relative" }}>
            <img
              src={FinanceAFA}
              alt="FinanceAFA"
              style={{ width: "100%", objectFit: "contain" }}
            />
            <Box
              sx={{
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                background:
                  "linear-gradient(to bottom, rgba(0,0,0,0), rgba(255,255,255,1) 95%)",
              }}
            ></Box>
          </Box>
        </Stack>

        <Box mt={5}>
          <Stack direction="row" alignItems="flex-end" gap={2} sx={{mb: 3}}>
            <Box>
              <img
                src={BarChartOutlined}
                alt="Barchart"
                style={{ height: "100px", objectFit: "contain" }}
              />
            </Box>
            <Typography variant="h2" fontWeight={400}>
              Why use loop?
            </Typography>
          </Stack>

          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={3}>
              <ImageCollapse
                src={AuditImg}
                title="Audit"
                description="Examine orders from Uber Eats, Doordash, and GrubHub to identify any hidden fees or fraudulent chargebacks"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <ImageCollapse
                src={DisputesImg}
                title="Disputes"
                description="Consider potential consequences, outcomes, case strength, and evidence when deciding which order or item to dispute."
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <ImageCollapse
                src={InvoicesImg}
                title="Invoices"
                description="Detailed invoices offer comprehensive overview of orders with host, order, and item level breakdowns for finance tracking and issue resolution."
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <ImageCollapse
                src={CustomizeImg}
                title="Customize"
                description="Customizable platform with features and options to fit your specific needs. Create pages, add panels, and adjust layout and content."
              />
            </Grid>
          </Grid>
        </Box>
      </Box>

      <Box
        sx={{
          px: 10,
          py: 5,
          mt: 5,
          background:
            "linear-gradient(to bottom, rgba(238, 232, 220, 1), transparent)",
        }}
      >
        <Stack direction="row" alignItems="center" gap={2}>
          <Typography variant="caption">Audit</Typography>
          <Divider sx={{ background: "black", width: "200px" }} />
        </Stack>

        <Grid container spacing={2}>
          <Grid item xs={12} md={1}>
            <img
              src={AuditImg}
              alt="Audit img"
              style={{ height: "60px", objectFit: "contain" }}
            />
          </Grid>
          <Grid item xs={12} md={5}>
            <Typography variant="h4" flex={1}>
              Examine, identify any hidden fees or fraudulent chargebacks
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="caption" flex={1}>
              Examine orders from Uber Eats, Doordash, and GrubHub to identify
              any hidden fees or fraudulent chargebacks that may be negatively
              affecting your profits. Integrate the audit data with your
              accounting software to have a comprehensive understanding of the
              situation.
            </Typography>
          </Grid>
        </Grid>

        <Stack mt={5}>
          <Typography variant="caption">
            Breakdown of payment down to cents
          </Typography>
        </Stack>

        <Box mt={5}>
          <BreakdownPayments
            label="Payout"
            title={1000}
            background="rgba(201, 218, 222, 1)"
            color="rgba(25, 110, 130, 1)"
            list={[
              {
                label: "Aggregation deduction",
                title: 750,
                background: "rgba(225, 225, 253, 1)",
                color: "rgba(67, 67, 188, 1)",
                list: [
                  {
                    label: "Doordash",
                    title: 250,
                    background: "rgba(245, 245, 252, 1)",
                    color: "rgba(97, 97, 200, 1)",
                  },
                  {
                    label: "Grubhub",
                    title: 250,
                    background: "rgba(245, 245, 252, 1)",
                    color: "rgba(97, 97, 200, 1)",
                  },
                  {
                    label: "UberEats",
                    title: 250,
                    background: "rgba(245, 245, 252, 1)",
                    color: "rgba(97, 97, 200, 1)",
                  },
                ],
              },
              {
                label: "Marketing",
                title: 250,
                background: "rgba(255, 199, 161, 1)",
                color: "rgba(203, 91, 15, 1)",
                list: [
                  {
                    label: "AD Credit",
                    title: 83.33,
                    background: "rgba(255, 224, 203, 1)",
                    color: "rgba(203, 91, 15, 1)",
                  },
                  {
                    label: "AD Spend",
                    title: 83.33,
                    background: "rgba(255, 224, 203, 1)",
                    color: "rgba(203, 91, 15, 1)",
                  },
                  {
                    label: "Discount",
                    title: 83.33,
                    background: "rgba(255, 224, 203, 1)",
                    color: "rgba(203, 91, 15, 1)",
                  },
                ],
              },
            ]}
          />
        </Box>
      </Box>

      <Box sx={{ px: 10, py: 5, mt: 5 }}>
        <Stack direction="row" alignItems="center" gap={2}>
          <Typography variant="caption">Disputes</Typography>
          <Divider sx={{ background: "black", width: "200px" }} />
        </Stack>

        <Grid container spacing={2} sx={{mt:5}}>
          <Grid item xs={12} md={1}>
          <img
            src={DisputesImg}
            alt="Audit img"
            style={{ height: "60px", objectFit: "contain" }}
          /></Grid>
          <Grid item xs={12} md={5}>
          <Typography variant="h4" flex={1}>
            Guidance on determining which orders or items to dispute.
          </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
          <Typography variant="caption" flex={1}>
            When deciding which order or item to dispute, it is important to
            consider the potential consequences and potential outcomes, the
            strength of your case, and any supporting evidence you may have.
          </Typography>
          </Grid>
        </Grid>

        <Box mt={5}>
          <img
            src={DisputeSectionImg}
            alt="dispute section"
            style={{ width: "100%", objectFit: "contain" }}
          />
        </Box>

        <Grid container spacing={2} sx={{mt:5}}>
          <Grid item xs={12} md={6}>
          <Typography variant="h4" flex={1}>
            Insights for disputable orders
          </Typography></Grid>
          <Grid item xs={12} md={6}>
          <Typography variant="caption" component={"p"} flex={1}>
            When deciding which order or item to dispute, it is important to
            consider the potential consequences and potential outcomes, the
            strength of your case, and any supporting evidence you may have.
          </Typography>
          </Grid>
        </Grid>

        <Box mt={3}>
          <img
            src={InsightDisputeImg}
            alt="Insight dispute"
            style={{ width: "100%", objectFit: "contain" }}
          />
        </Box>
      </Box>

      <Box sx={{ px: 10, py: 5, mt: 5 }}>
        <Stack direction="row" alignItems="center" gap={2}>
          <Typography variant="caption">Invoices</Typography>
          <Divider sx={{ background: "black", width: "200px" }} />
        </Stack>

        <Stack direction="row" alignItems={"center"} gap={2} mt={5}>
          <img
            src={InvoicesImg}
            alt="Audit img"
            style={{ height: "60px", objectFit: "contain" }}
          />
          <Typography variant="h4" flex={1}>
            Detailed invoices
          </Typography>
          <Typography variant="caption" flex={1}>
            The detailed invoices feature provides insights on a host level
            breakdown, order level breakdown, and item level breakdown, allowing
            users to see a comprehensive overview of orders. This can be useful
            for tracking and managing finances, as well as identifying and
            resolving any issues with orders.
          </Typography>
        </Stack>

        <Stack direction="row" alignItems={"stretch"} gap={2} mt={5}>
          <Box flex={1}>
            <LevelBreakdownCards type="host" />
          </Box>
          <Box flex={1}>
            <LevelBreakdownCards type="order" />
          </Box>
          <Box flex={1}>
            <LevelBreakdownCards type="item" />
          </Box>
        </Stack>

        <Stack px={5} mt={3} alignItems="center" justifyContent="center">
          <img
            src={DetailedInvoiceImg}
            alt="detailed_invoice_image"
            style={{ width: "100%", objectFit: "contain" }}
          />
        </Stack>
      </Box>

      <Box sx={{ px: 10, py: 5, mt: 5 }}>
        <Stack direction="row" alignItems="center" gap={2}>
          <Typography variant="caption">Tailor to your needs</Typography>
          <Divider sx={{ background: "black", width: "200px" }} />
        </Stack>

        <Stack direction="row" alignItems={"center"} gap={2} mt={5}>
          <img
            src={CustomizeImg}
            alt="Audit img"
            style={{ height: "60px", objectFit: "contain" }}
          />
          <Typography variant="h4" flex={1}>
            Create pages, add panels, and adjust them as desired
          </Typography>
          <Typography variant="caption" flex={1}>
            Our platform is tailored to your needs, offering customizable
            features and options that allow you to tailor your experience to
            meet your specific requirements and preferences. We allows users to
            customize their platform experience by creating new pages, adding
            panels (which are essentially blocks of content or information), and
            adjusting the layout and content of these panels to meet their
            specific needs and preferences
          </Typography>
        </Stack>

        <Box>
          <img
            src={CustomizeSectionImg}
            alt="Customize Section"
            style={{ width: "100%", objectFit: "contain" }}
          />
        </Box>
      </Box>

      <Box
        sx={{
          px: 5,
          py: 5,
          mx: 10,
          mt: 5,
          background: "white",
          zIndex: 5,
          position: "relative",
        }}
      >
        <Typography variant="h4" fontWeight={400}>
          Third party companies
        </Typography>
        <Typography variant="body1" mt={2}>
          Our platform is a merge of insights from three popular food delivery
          platforms: Uber Eats, DoorDash, and GrubHub. This means that our
          platform provides users with a comprehensive view of their orders and
          purchases across all three platforms, making it easier to track and
          manage their finances and resolve any issues with orders.
        </Typography>

        <Stack
          direction="row"
          alignItems={"center"}
          justifyContent="space-between"
          mt={5}
          gap={2}
        >
          <img
            src={UberEatsImg}
            alt="UberEats"
            style={{ flex: 1, maxWidth: "150px", objectFit: "contain" }}
          />
          <img
            src={DoordashImg}
            alt="Doordash"
            style={{ flex: 1, maxWidth: "150px", objectFit: "contain" }}
          />
          <img
            src={GrubhubImg}
            alt="Grubhug"
            style={{ flex: 1, maxWidth: "150px", objectFit: "contain" }}
          />
        </Stack>
      </Box>

      {/* <Box
        sx={{
          background: "rgba(18, 41, 55, 1)",
          color: "white",
          px: 5,
          pb: 5,
          pt: "130px",
          mt: "-75px",
        }}
      >
        <Stack direction="row" alignItems="center" position={"relative"}>
          <Box flex={1}>
            <Typography variant="h4" fontWeight={400} color="inherit">
              We don’t use your data to compete with you
            </Typography>
            <ul>
              <li>
                <Typography variant="body1" color="inherit">
                  Data is everything (after location) and your data is safe with
                  us{" "}
                </Typography>
              </li>
              <li>
                <Typography variant="body1" color="inherit">
                  That’s because We don’t operate a food brand and never will
                </Typography>
              </li>
              <li>
                <Typography variant="body1" color="inherit">
                  Our goal is to enable and maximize your performance
                </Typography>
              </li>
              <li>
                <Typography variant="body1" color="inherit">
                  Contractually limit and delete your data
                </Typography>
              </li>
            </ul>
            <Button sx={{ mt: 3 }}>BOOK A DEMO</Button>
          </Box>
          <Box
            display="flex"
            alignItems={"center"}
            justifyContent={"center"}
            sx={{
              width: "50%",
              height: "100%",
              overflow: "hidden",
              position: "absolute",
              top: 0,
              right: 0,
            }}
          >
            <img
              src={SecureCirclesImg}
              alt=""
              style={{
                width: "100%",
                height: "100%",
                maxWidth: "50vw",
                maxHeight: "90vh",
                minWidth: "30vw",
                minHeight: "50vh",
                objectFit: "cover",
                objectPosition: "center",
              }}
            />
          </Box>
        </Stack>
      </Box> */}

      <Box sx={{ mx: 10, mt: 10 }}>
        <Box>
          <Typography
            sx={{ fontWeight: 400, fontSize: "52px", textAlign: "center" }}
          >
            Manage your order finances, dispute orders, and understand your
            payment breakdown.
          </Typography>
        </Box>

        <Box sx={{ mt: 5 }}>
          <Typography
            fontSize="30px"
            fontWeight={400}
            textAlign="center"
            sx={{ opacity: 0.4 }}
          >
            We make managing your business simple and easy.
          </Typography>
        </Box>

        <Stack alignItems="center" justifyContent="center" mt={5}>
          <FinanceButton size="large" sx={{ px: 8, py: 1, fontSize: "22px" }}>
            Book a Demo
          </FinanceButton>
        </Stack>
      </Box>

      {/* <Box sx={{ px: 10, mt: 10, py: 5, background: "rgba(18, 41, 55, 1)", color: "white" }} >
        <Stack direction="row" alignItems={"center"} justifyContent="space-between" >
          <Box>
            <img src={FooterLogo} alt="Loop logo" style={{ height: "35px", objectFit: "contain" }} />
            <Typography variant="body1" color="inherit" sx={{ mb: 1 }} ><b>WE ARE HERE</b></Typography>
            <Typography variant="body2" color="inherit" >548 Market St<br />Suite 39587<br />San Francisco, California<br />94104-5401 US</Typography>
          </Box>
          <Stack direction="column" alignItems={"flex-end"} >
            <Typography variant="subtitle2" color="inherit" >TRY FOR FREE</Typography>
            <FinanceButton sx={{ my: 1 }} >TRY FOR FREE</FinanceButton>
            <Typography variant="h3" color="inherit" >hello@loopkithechen.xyz</Typography>
          </Stack>
        </Stack>
        <Divider sx={{ background: "white", my: 2 }} />
        <Box>
          <Typography variant='caption' component="p" color="inherit" sx={{ opacity: 0.84 }} >©2022 All Rights Reserved. LOOP.</Typography>
        </Box>
      </Box> */}
    </Box>
  );
}
