import React from "react";
import Snackbar from '@mui/material/Snackbar'
import Alert from '@mui/material/Alert'
import { closeSnack } from "store/reducers/snack.slice";
import { useAppDispatch, useAppSelector } from "hooks";

function SnacbarComponent() {
  const dispatch = useAppDispatch();
  const { open, type, text }: { type: any, open: boolean, text: string | undefined } = useAppSelector(state => state.snack)
  return (
    <Snackbar
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      open={open}
      autoHideDuration={8000}
      onClose={() => dispatch(closeSnack())}
    >
      <Alert variant="filled" severity={type} sx={{ width: "100%" }} onClose={() => dispatch(closeSnack())}>
        {text}
      </Alert>
    </Snackbar >
  );
}

export default SnacbarComponent;
