import React from 'react'
import Box from '@mui/material/Box'
import { styled, SxProps } from '@mui/material/styles'
import Typography from '@mui/material/Typography'

export const StyledTypo = styled(props => <Typography variant="h3" {...props} />)(({ fs, fw, c }: { fs: number, fw: number, c: string }) => ({
    fontSize: fs || 16,
    fontWeight: fw || 600,
    color: c
}))

export function CustomTypoContainer(props: any) {
    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center'
        }}>
            <StyledTypo {...props} />
        </Box>
    )
}

export function TypoContainer({ children, sx }: { children: React.ReactNode, sx?: SxProps }) {
    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            textAlign: 'center',
            ...sx
        }}>
            {children}
        </Box>
    )
}

export default function CustomTypo(props: any) {
    return (
        <StyledTypo {...props} />
    )
}
