import React from 'react'
import Box from '@mui/material/Box'
import Collapse from '@mui/material/Collapse'
import { useTheme, useMediaQuery } from '@mui/material'
import CustomTypo from 'components/mui/StyledTypo'

interface ImageCollapsePorps {
    src: string
    title: string
    description: string
}

export default function ImageCollapse(props: ImageCollapsePorps) {
    const theme = useTheme()
    const xs = useMediaQuery(theme.breakpoints.down('sm'))
    const [collapseOpen, setCollapseOpen] = React.useState(false);

    React.useEffect(()=>{
        if (xs===true) setCollapseOpen(true)
        else if (xs=== false) setCollapseOpen(false)
    },[xs])

    return (
        <Box sx={{ position: "relative", p: 3, background: "white", mb: 2 }} onMouseOver={() => { setCollapseOpen(true) }} onMouseOut={() => { setCollapseOpen(false) }} >
            <img src={props.src} alt={props.title} style={{ height: "57px", objectFit: "contain" }} />
            <CustomTypo fs={24} fw={400}>{props.title}</CustomTypo>
            <Collapse in={collapseOpen}  sx={{pt: 1}}>
                <CustomTypo fs={16} fw={300} sx={{ opacity: 0.75 }} >{props.description}</CustomTypo>
            </Collapse>
        </Box>
    )
}
