import { Button, ButtonProps } from '@mui/material'
import React from 'react'

interface FinanceButtonProps extends ButtonProps {

}

export default function FinanceButton(props: FinanceButtonProps) {

    return (
        <Button variant="contained" {...props} sx={{ background: "rgba(241, 84, 18, 1)", color: "white", "&:hover": { background: "rgba(241, 84, 18, 1)", color: "white" }, ...props.sx }} />
    )
}
